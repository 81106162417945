// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const Data = {
  pageName: 'ButtonsCSS Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'Sampension',
    pageDescription: 'Sampensions web site',
    bannerImage: null,
    bannerContent: '<h1>banner overskrift</h1><p>banner manchet</p>',
    bottomContent: null,
    desktopTools: {},
  },
  pageContent: [
    // HTML Render

    {
      componentName: 'HtmlRender',
      componentData: {
        html:
          '<center><a href="#" class="primaryBtn">Primary Button</a><br /><br />'
          + '<a href="#" class="primary2Btn">Primary Button 2</a><br /><br />'
          + '<a href="#" class="secondaryBtn">Secondary Button</a></center><br/><br />',
      },
    },
  ],
};

export default Data;
